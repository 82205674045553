import * as React from "react";

function App() {
  return (
    <div className="flex flex-col items-start justify-center min-h-screen w-full bg-white">
      <div className="flex gap-5 justify-between self-center px-8 w-full max-w-screen-xl text-base font-medium leading-6 text-white max-md:flex-wrap max-md:px-5 max-md:max-w-full">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/b166b1237a0084c2f52e6d87c9d2f20edf5e96d4caf7a443b68562619d3d9566?apiKey=94100daacd774519986c0b390f256b6a&"
          className="shrink-0 aspect-[1.27] w-[71px]"
        />
        <div className="flex flex-col justify-center my-auto bg-teal-400 rounded-lg" />
      </div>
      <div className="mt-3 w-full bg-gray-100 min-h-[1px] max-md:max-w-full" />
      <div className="flex flex-col items-center px-16 pt-16 w-full max-md:px-5 max-md:max-w-full">
        <div className="w-full max-w-[1217px] max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            <div className="flex flex-col w-[58%] max-md:ml-0 max-md:w-full">
              <div className="flex flex-col grow max-md:max-w-full">
                <div className="text-5xl font-semibold text-gray-800 max-md:max-w-full">
                  Empowering GAIT:  Fast, Accurate, Global.
                </div>
                <div className="mt-5 text-lg leading-7 text-gray-500 max-md:max-w-full">
                  Software solution for medical professionals to develop
                  individualized, targeted and precise automated diagnosis and
                  treatment plans for gait-related disorders.
                </div>
                <div className="flex gap-5 justify-between self-start mt-12 font-medium max-md:flex-wrap max-md:mt-10" />
              </div>
            </div>
            <div className="flex flex-col ml-5 w-[42%] max-md:ml-0 max-md:w-full">
              <div className="flex flex-col grow justify-center max-md:max-w-full">
                <div className="flex overflow-hidden relative flex-col pt-20 pr-8 pb-3.5 pl-20 w-full min-h-[332px] max-md:px-5 max-md:max-w-full">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/da1235ac12e9553ed8a0d7d9004d3b6841d17decda352cb1467dcb2d27dce69d?apiKey=94100daacd774519986c0b390f256b6a&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/da1235ac12e9553ed8a0d7d9004d3b6841d17decda352cb1467dcb2d27dce69d?apiKey=94100daacd774519986c0b390f256b6a&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/da1235ac12e9553ed8a0d7d9004d3b6841d17decda352cb1467dcb2d27dce69d?apiKey=94100daacd774519986c0b390f256b6a&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/da1235ac12e9553ed8a0d7d9004d3b6841d17decda352cb1467dcb2d27dce69d?apiKey=94100daacd774519986c0b390f256b6a&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/da1235ac12e9553ed8a0d7d9004d3b6841d17decda352cb1467dcb2d27dce69d?apiKey=94100daacd774519986c0b390f256b6a&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/da1235ac12e9553ed8a0d7d9004d3b6841d17decda352cb1467dcb2d27dce69d?apiKey=94100daacd774519986c0b390f256b6a&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/da1235ac12e9553ed8a0d7d9004d3b6841d17decda352cb1467dcb2d27dce69d?apiKey=94100daacd774519986c0b390f256b6a&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/da1235ac12e9553ed8a0d7d9004d3b6841d17decda352cb1467dcb2d27dce69d?apiKey=94100daacd774519986c0b390f256b6a&"
                    className="object-cover absolute inset-0 size-full"
                  />
                  <div className="flex relative flex-col self-end pl-20 mt-28 max-w-full w-[292px] max-md:pl-5 max-md:mt-10">
                    <div className="shrink-0 bg-violet-500 rounded-full h-[9px] w-[9px]" />
                    <div className="shrink-0 self-end mt-11 bg-purple-800 rounded-full h-[9px] w-[9px] max-md:mt-10" />
                  </div>
                  <div className="relative shrink-0 self-center mt-14 bg-purple-800 rounded-full h-[9px] w-[9px] max-md:mt-10" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col self-center p-8 mt-24 w-full max-w-screen-xl bg-white max-md:px-5 max-md:mt-10 max-md:max-w-full">
        <div className="text-base font-semibold leading-6 text-center text-purple-700 max-md:max-w-full">
          Partners
        </div>
        <div className="flex gap-5 justify-between mt-8 max-md:flex-wrap max-md:max-w-full">
          <img
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/9677b5059c0670fb5d29369dfbf357a4e481dd36da345eb27590e2859488f388?apiKey=94100daacd774519986c0b390f256b6a&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/9677b5059c0670fb5d29369dfbf357a4e481dd36da345eb27590e2859488f388?apiKey=94100daacd774519986c0b390f256b6a&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9677b5059c0670fb5d29369dfbf357a4e481dd36da345eb27590e2859488f388?apiKey=94100daacd774519986c0b390f256b6a&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/9677b5059c0670fb5d29369dfbf357a4e481dd36da345eb27590e2859488f388?apiKey=94100daacd774519986c0b390f256b6a&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/9677b5059c0670fb5d29369dfbf357a4e481dd36da345eb27590e2859488f388?apiKey=94100daacd774519986c0b390f256b6a&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9677b5059c0670fb5d29369dfbf357a4e481dd36da345eb27590e2859488f388?apiKey=94100daacd774519986c0b390f256b6a&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/9677b5059c0670fb5d29369dfbf357a4e481dd36da345eb27590e2859488f388?apiKey=94100daacd774519986c0b390f256b6a&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/9677b5059c0670fb5d29369dfbf357a4e481dd36da345eb27590e2859488f388?apiKey=94100daacd774519986c0b390f256b6a&"
            className="shrink-0 max-w-full aspect-[1.92] w-[115px]"
          />
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/b931b1a34e63eaa90cc660738852afc01f9f85c5164f6e15a6bb824638cdc817?apiKey=94100daacd774519986c0b390f256b6a&"
            className="aspect-[5.88] w-[354px]"
          />
          <img
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/8f54bced241410cb0db84b6c01755cbcb07203e35cca94611a0ce73544a8b9c3?apiKey=94100daacd774519986c0b390f256b6a&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/8f54bced241410cb0db84b6c01755cbcb07203e35cca94611a0ce73544a8b9c3?apiKey=94100daacd774519986c0b390f256b6a&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/8f54bced241410cb0db84b6c01755cbcb07203e35cca94611a0ce73544a8b9c3?apiKey=94100daacd774519986c0b390f256b6a&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/8f54bced241410cb0db84b6c01755cbcb07203e35cca94611a0ce73544a8b9c3?apiKey=94100daacd774519986c0b390f256b6a&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/8f54bced241410cb0db84b6c01755cbcb07203e35cca94611a0ce73544a8b9c3?apiKey=94100daacd774519986c0b390f256b6a&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/8f54bced241410cb0db84b6c01755cbcb07203e35cca94611a0ce73544a8b9c3?apiKey=94100daacd774519986c0b390f256b6a&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/8f54bced241410cb0db84b6c01755cbcb07203e35cca94611a0ce73544a8b9c3?apiKey=94100daacd774519986c0b390f256b6a&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/8f54bced241410cb0db84b6c01755cbcb07203e35cca94611a0ce73544a8b9c3?apiKey=94100daacd774519986c0b390f256b6a&"
            className="shrink-0 max-w-full aspect-[2.38] w-[142px]"
          />
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/8246721e7352c8367c39c5995a73b107571b0e2e08f62cb2c8fa51bf432c6db9?apiKey=94100daacd774519986c0b390f256b6a&"
            className="shrink-0 max-w-full aspect-[2.86] w-[172px]"
          />
          <img
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/e8fec6b819a538454c89056747a979357533b1844ec0f0c6606de978b6d3198b?apiKey=94100daacd774519986c0b390f256b6a&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/e8fec6b819a538454c89056747a979357533b1844ec0f0c6606de978b6d3198b?apiKey=94100daacd774519986c0b390f256b6a&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e8fec6b819a538454c89056747a979357533b1844ec0f0c6606de978b6d3198b?apiKey=94100daacd774519986c0b390f256b6a&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/e8fec6b819a538454c89056747a979357533b1844ec0f0c6606de978b6d3198b?apiKey=94100daacd774519986c0b390f256b6a&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/e8fec6b819a538454c89056747a979357533b1844ec0f0c6606de978b6d3198b?apiKey=94100daacd774519986c0b390f256b6a&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e8fec6b819a538454c89056747a979357533b1844ec0f0c6606de978b6d3198b?apiKey=94100daacd774519986c0b390f256b6a&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/e8fec6b819a538454c89056747a979357533b1844ec0f0c6606de978b6d3198b?apiKey=94100daacd774519986c0b390f256b6a&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/e8fec6b819a538454c89056747a979357533b1844ec0f0c6606de978b6d3198b?apiKey=94100daacd774519986c0b390f256b6a&"
            className="shrink-0 max-w-full aspect-[2.33] w-[140px]"
          />
        </div>
        <div className="flex justify-center items-center px-16 pt-6 mt-8 max-md:px-5 max-md:max-w-full">
          <div className="flex gap-5 justify-between w-full max-w-[1002px] max-md:flex-wrap max-md:max-w-full">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/2187aa3a9ac55f471e005e10a1e6d4b7ce0e8c700f4a90fb95fad47f72471c5a?apiKey=94100daacd774519986c0b390f256b6a&"
              className="aspect-[4.17] w-[255px]"
            />
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/cc6edfc5d9cebaf7801c78ba44fb2f6c91328e80a721586423cc4a8028c30947?apiKey=94100daacd774519986c0b390f256b6a&"
              className="shrink-0 w-32 max-w-full aspect-[2.13]"
            />
            <img
              loading="lazy"
              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/1619805cd266f3cd5c9ad49b0e68a2c326f3dc714efc9a2faad93c9347a015fc?apiKey=94100daacd774519986c0b390f256b6a&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/1619805cd266f3cd5c9ad49b0e68a2c326f3dc714efc9a2faad93c9347a015fc?apiKey=94100daacd774519986c0b390f256b6a&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1619805cd266f3cd5c9ad49b0e68a2c326f3dc714efc9a2faad93c9347a015fc?apiKey=94100daacd774519986c0b390f256b6a&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/1619805cd266f3cd5c9ad49b0e68a2c326f3dc714efc9a2faad93c9347a015fc?apiKey=94100daacd774519986c0b390f256b6a&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/1619805cd266f3cd5c9ad49b0e68a2c326f3dc714efc9a2faad93c9347a015fc?apiKey=94100daacd774519986c0b390f256b6a&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1619805cd266f3cd5c9ad49b0e68a2c326f3dc714efc9a2faad93c9347a015fc?apiKey=94100daacd774519986c0b390f256b6a&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/1619805cd266f3cd5c9ad49b0e68a2c326f3dc714efc9a2faad93c9347a015fc?apiKey=94100daacd774519986c0b390f256b6a&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/1619805cd266f3cd5c9ad49b0e68a2c326f3dc714efc9a2faad93c9347a015fc?apiKey=94100daacd774519986c0b390f256b6a&"
              className="shrink-0 aspect-square w-[60px]"
            />
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/7597ded3f37bc9e9e2787e5d046053ac3902f0d248fe35a13fba930ca4375372?apiKey=94100daacd774519986c0b390f256b6a&"
              className="aspect-[5.26] w-[319px]"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center self-center px-8 py-16 mt-24 w-full max-w-screen-xl text-center text-gray-500 bg-gray-50 max-md:px-5 max-md:mt-10 max-md:max-w-full">
        <div className="ml-14 text-4xl font-semibold tracking-tighter leading-10 w-[656px] max-md:max-w-full">
          AI-based Solution for Gait Disorder Diagnosis and Treatment{" "}
        </div>
        <div className="mt-8 text-lg leading-7 w-[768px] max-md:max-w-full">
          The only AI-based med-tech solution that facilitates gait disorder
          diagnosis and treatment for the global community of medical
          professionals. It addresses the challenges of time-consuming manual
          analysis in an era of data-driven automated decision-making.
        </div>
      </div>
      <div className="flex flex-col p-20 w-full bg-white max-md:px-5 max-md:max-w-full">
        <div className="flex flex-col items-center px-8 mt-4 font-semibold text-center leading-[150%] max-md:px-5 max-md:max-w-full">
          <div className="self-stretch text-base text-purple-700 max-md:max-w-full">
            Features
          </div>
          <div className="mt-3 text-4xl tracking-tighter leading-10 text-gray-800 max-md:max-w-full">
            Benefits that you will get.
          </div>
          <div className="mt-5 text-xl text-gray-500 max-md:max-w-full">
            Fast, Accurate, Global
          </div>
        </div>
        <div className="pb-6 mt-16 mb-4 max-md:pl-5 max-md:mt-10 max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            <div className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
              <div className="flex flex-col grow text-center max-md:mt-10">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/77d6fa04e5181eb3892a134b0b235326d43ff12a9d63649367bc2a7c7e6e52c3?apiKey=94100daacd774519986c0b390f256b6a&"
                  className="self-center w-12 aspect-square"
                />
                <div className="mt-5 text-xl font-medium leading-8 text-gray-900">
                  Time Saving
                </div>
                <div className="mt-2 text-lg leading-6 text-gray-500">
                  Our product addresses the challenges of time-consuming manual
                  analysis and improving the duration of gait data
                  interpretation process for up to{" "}
                  <span className="text-gray-500">90%</span>.{" "}
                </div>
              </div>
            </div>
            <div className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
              <div className="flex flex-col grow text-center max-md:mt-10">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/77d6fa04e5181eb3892a134b0b235326d43ff12a9d63649367bc2a7c7e6e52c3?apiKey=94100daacd774519986c0b390f256b6a&"
                  className="self-center w-12 aspect-square"
                />
                <div className="mt-5 text-xl font-medium leading-8 text-gray-900">
                  Accurate Diagnosis
                </div>
                <div className="mt-2 text-lg leading-6 text-gray-500">
                  Detect subtle gait abnormalities that may be missed by human
                  observation alone, leading to more accurate and comprehensive
                  diagnoses.
                </div>
              </div>
            </div>
            <div className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
              <div className="flex flex-col grow text-center max-md:mt-10">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/77d6fa04e5181eb3892a134b0b235326d43ff12a9d63649367bc2a7c7e6e52c3?apiKey=94100daacd774519986c0b390f256b6a&"
                  className="self-center w-12 aspect-square"
                />
                <div className="mt-5 text-xl font-medium leading-8 text-gray-900">
                  Effective Treatment
                </div>
                <div className="mt-2 text-lg leading-6 text-gray-500">
                  Our AI solution combined with global medical expertise in one
                  platform, thus becoming an aid in treatment planning and
                  progress tracking.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center px-20 pt-16 pb-20 w-full bg-white max-md:px-5 max-md:max-w-full">
        <div className="text-base font-semibold leading-6 text-center text-violet-500 max-md:max-w-full">
          About us
        </div>
        <div className="mt-3 text-4xl font-semibold tracking-tighter leading-10 text-center text-gray-900 max-md:max-w-full">
          Meet our team
        </div>
        <div className="mt-5 text-xl leading-8 text-center text-gray-500 w-[768px] max-md:max-w-full">
          Our philosophy is simple — hire a team of diverse, passionate people
          and foster a culture that empowers you to do your best work.
        </div>
        <img
          loading="lazy"
          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/056d0ecde207e9ec2f4910b769732def8d634f8bfb230f539aa57f98dd834fac?apiKey=94100daacd774519986c0b390f256b6a&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/056d0ecde207e9ec2f4910b769732def8d634f8bfb230f539aa57f98dd834fac?apiKey=94100daacd774519986c0b390f256b6a&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/056d0ecde207e9ec2f4910b769732def8d634f8bfb230f539aa57f98dd834fac?apiKey=94100daacd774519986c0b390f256b6a&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/056d0ecde207e9ec2f4910b769732def8d634f8bfb230f539aa57f98dd834fac?apiKey=94100daacd774519986c0b390f256b6a&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/056d0ecde207e9ec2f4910b769732def8d634f8bfb230f539aa57f98dd834fac?apiKey=94100daacd774519986c0b390f256b6a&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/056d0ecde207e9ec2f4910b769732def8d634f8bfb230f539aa57f98dd834fac?apiKey=94100daacd774519986c0b390f256b6a&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/056d0ecde207e9ec2f4910b769732def8d634f8bfb230f539aa57f98dd834fac?apiKey=94100daacd774519986c0b390f256b6a&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/056d0ecde207e9ec2f4910b769732def8d634f8bfb230f539aa57f98dd834fac?apiKey=94100daacd774519986c0b390f256b6a&"
          className="mt-16 w-full aspect-[3.03] max-w-[1216px] max-md:mt-10 max-md:max-w-full"
        />
        <div className="flex flex-col self-stretch px-8 mt-16 mb-4 max-md:px-5 max-md:mt-10 max-md:max-w-full">
          <div className="max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
              <div className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
                <div className="flex flex-col grow self-stretch p-6 w-full bg-gray-50 max-md:px-5 max-md:mt-8">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/6ab583580498223db807715aaaa3343f7c86c5eae7d9dc0f2a8ae8eff9ecc469?apiKey=94100daacd774519986c0b390f256b6a&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/6ab583580498223db807715aaaa3343f7c86c5eae7d9dc0f2a8ae8eff9ecc469?apiKey=94100daacd774519986c0b390f256b6a&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/6ab583580498223db807715aaaa3343f7c86c5eae7d9dc0f2a8ae8eff9ecc469?apiKey=94100daacd774519986c0b390f256b6a&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/6ab583580498223db807715aaaa3343f7c86c5eae7d9dc0f2a8ae8eff9ecc469?apiKey=94100daacd774519986c0b390f256b6a&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/6ab583580498223db807715aaaa3343f7c86c5eae7d9dc0f2a8ae8eff9ecc469?apiKey=94100daacd774519986c0b390f256b6a&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/6ab583580498223db807715aaaa3343f7c86c5eae7d9dc0f2a8ae8eff9ecc469?apiKey=94100daacd774519986c0b390f256b6a&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/6ab583580498223db807715aaaa3343f7c86c5eae7d9dc0f2a8ae8eff9ecc469?apiKey=94100daacd774519986c0b390f256b6a&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/6ab583580498223db807715aaaa3343f7c86c5eae7d9dc0f2a8ae8eff9ecc469?apiKey=94100daacd774519986c0b390f256b6a&"
                    className="self-center max-w-full aspect-square w-[120px]"
                  />
                  <div className="mt-5 text-lg font-medium leading-7 text-center text-gray-900">
                    Hovan Dashtoyan
                  </div>
                  <div className="mt-2 text-base leading-6 text-center text-gray-500">
                    PhD, MBA candidate, Responsible Engineer of  Wigmore Gait
                    Lab, Technical Writer at ClinChoice
                  </div>
                  <div className="flex justify-center items-center px-16 mt-4 max-md:px-5">
                    <div className="flex gap-4" />
                  </div>
                </div>
              </div>
              <div className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
                <div className="flex flex-col grow self-stretch p-6 w-full bg-gray-50 max-md:px-5 max-md:mt-8">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/c42b736f38da12b139c8e49fd15f1b60929b5e96407990663dcf6e3dc83b7f59?apiKey=94100daacd774519986c0b390f256b6a&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/c42b736f38da12b139c8e49fd15f1b60929b5e96407990663dcf6e3dc83b7f59?apiKey=94100daacd774519986c0b390f256b6a&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c42b736f38da12b139c8e49fd15f1b60929b5e96407990663dcf6e3dc83b7f59?apiKey=94100daacd774519986c0b390f256b6a&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/c42b736f38da12b139c8e49fd15f1b60929b5e96407990663dcf6e3dc83b7f59?apiKey=94100daacd774519986c0b390f256b6a&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/c42b736f38da12b139c8e49fd15f1b60929b5e96407990663dcf6e3dc83b7f59?apiKey=94100daacd774519986c0b390f256b6a&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c42b736f38da12b139c8e49fd15f1b60929b5e96407990663dcf6e3dc83b7f59?apiKey=94100daacd774519986c0b390f256b6a&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/c42b736f38da12b139c8e49fd15f1b60929b5e96407990663dcf6e3dc83b7f59?apiKey=94100daacd774519986c0b390f256b6a&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/c42b736f38da12b139c8e49fd15f1b60929b5e96407990663dcf6e3dc83b7f59?apiKey=94100daacd774519986c0b390f256b6a&"
                    className="self-center max-w-full aspect-square w-[120px]"
                  />
                  <div className="mt-5 text-lg font-medium leading-7 text-center text-gray-900">
                    Davit Sekoyan
                  </div>
                  <div className="mt-2 text-base leading-6 text-center text-gray-500">
                    Pediatric orthopedic surgeon, Wigmore Gait Lab
                    coordinator, Medical Writer at ClinChoice, MD
                  </div>
                  <div className="flex justify-center items-center px-16 mt-4 max-md:px-5">
                    <div className="flex gap-4" />
                  </div>
                </div>
              </div>
              <div className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
                <div className="flex flex-col grow self-stretch p-6 w-full bg-gray-50 max-md:px-5 max-md:mt-8">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/d01b3bc61b8582115ec22510d70d736d9da0963bbba98144b073167e34e61d50?apiKey=94100daacd774519986c0b390f256b6a&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/d01b3bc61b8582115ec22510d70d736d9da0963bbba98144b073167e34e61d50?apiKey=94100daacd774519986c0b390f256b6a&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d01b3bc61b8582115ec22510d70d736d9da0963bbba98144b073167e34e61d50?apiKey=94100daacd774519986c0b390f256b6a&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/d01b3bc61b8582115ec22510d70d736d9da0963bbba98144b073167e34e61d50?apiKey=94100daacd774519986c0b390f256b6a&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/d01b3bc61b8582115ec22510d70d736d9da0963bbba98144b073167e34e61d50?apiKey=94100daacd774519986c0b390f256b6a&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d01b3bc61b8582115ec22510d70d736d9da0963bbba98144b073167e34e61d50?apiKey=94100daacd774519986c0b390f256b6a&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/d01b3bc61b8582115ec22510d70d736d9da0963bbba98144b073167e34e61d50?apiKey=94100daacd774519986c0b390f256b6a&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/d01b3bc61b8582115ec22510d70d736d9da0963bbba98144b073167e34e61d50?apiKey=94100daacd774519986c0b390f256b6a&"
                    className="self-center max-w-full aspect-square w-[120px]"
                  />
                  <div className="mt-5 text-lg font-medium leading-7 text-center text-gray-900">
                    Sevan Yeritsian
                  </div>
                  <div className="mt-2 text-base leading-6 text-center text-gray-500">
                    Pediatric orthopedic surgeon, Gait Lab Specialist, MD
                  </div>
                  <div className="flex justify-center items-center px-16 mt-10 max-md:px-5">
                    <div className="flex gap-4" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-8 max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
              <div className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
                <div className="flex flex-col grow self-stretch p-6 w-full text-center bg-gray-50 max-md:px-5 max-md:mt-8">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/9b7ff925df54b4a7e5e7d0428e4bd347a147f421ddc84fcdcab5b28305818cc6?apiKey=94100daacd774519986c0b390f256b6a&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/9b7ff925df54b4a7e5e7d0428e4bd347a147f421ddc84fcdcab5b28305818cc6?apiKey=94100daacd774519986c0b390f256b6a&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9b7ff925df54b4a7e5e7d0428e4bd347a147f421ddc84fcdcab5b28305818cc6?apiKey=94100daacd774519986c0b390f256b6a&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/9b7ff925df54b4a7e5e7d0428e4bd347a147f421ddc84fcdcab5b28305818cc6?apiKey=94100daacd774519986c0b390f256b6a&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/9b7ff925df54b4a7e5e7d0428e4bd347a147f421ddc84fcdcab5b28305818cc6?apiKey=94100daacd774519986c0b390f256b6a&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9b7ff925df54b4a7e5e7d0428e4bd347a147f421ddc84fcdcab5b28305818cc6?apiKey=94100daacd774519986c0b390f256b6a&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/9b7ff925df54b4a7e5e7d0428e4bd347a147f421ddc84fcdcab5b28305818cc6?apiKey=94100daacd774519986c0b390f256b6a&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/9b7ff925df54b4a7e5e7d0428e4bd347a147f421ddc84fcdcab5b28305818cc6?apiKey=94100daacd774519986c0b390f256b6a&"
                    className="self-center max-w-full aspect-square w-[120px]"
                  />
                  <div className="mt-5 text-lg font-medium leading-7 text-gray-900">
                    Vigen Baghdasaryan
                  </div>
                  <div className="mt-2 text-base leading-6 text-gray-500">
                    Senior software engineer at Zealous, Database design and
                    programming
                  </div>
                </div>
              </div>
              <div className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
                <div className="flex flex-col grow self-stretch p-6 w-full bg-gray-50 max-md:px-5 max-md:mt-8">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/e23e76df4800df6fc6812e39396e845ad66f6fcda6a8464a8669636999bd82e5?apiKey=94100daacd774519986c0b390f256b6a&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/e23e76df4800df6fc6812e39396e845ad66f6fcda6a8464a8669636999bd82e5?apiKey=94100daacd774519986c0b390f256b6a&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e23e76df4800df6fc6812e39396e845ad66f6fcda6a8464a8669636999bd82e5?apiKey=94100daacd774519986c0b390f256b6a&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/e23e76df4800df6fc6812e39396e845ad66f6fcda6a8464a8669636999bd82e5?apiKey=94100daacd774519986c0b390f256b6a&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/e23e76df4800df6fc6812e39396e845ad66f6fcda6a8464a8669636999bd82e5?apiKey=94100daacd774519986c0b390f256b6a&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e23e76df4800df6fc6812e39396e845ad66f6fcda6a8464a8669636999bd82e5?apiKey=94100daacd774519986c0b390f256b6a&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/e23e76df4800df6fc6812e39396e845ad66f6fcda6a8464a8669636999bd82e5?apiKey=94100daacd774519986c0b390f256b6a&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/e23e76df4800df6fc6812e39396e845ad66f6fcda6a8464a8669636999bd82e5?apiKey=94100daacd774519986c0b390f256b6a&"
                    className="self-center max-w-full aspect-square w-[120px]"
                  />
                  <div className="mt-5 text-lg font-medium leading-7 text-center text-slate-800">
                    David Dashtoyan
                  </div>
                  <div className="mt-2 text-base leading-6 text-center text-gray-500">
                    Software engineer, B.S. in Computer Science, AUA
                  </div>
                  <div className="flex justify-center items-center px-16 mt-10 max-md:px-5">
                    <div className="flex gap-4" />
                  </div>
                </div>
              </div>
              <div className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
                <div className="flex flex-col grow self-stretch p-6 w-full bg-gray-50 max-md:px-5 max-md:mt-8">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/eb33eae22384eb25f2f1d70910e1e5803eeb8964417005a674425a09357002f4?apiKey=94100daacd774519986c0b390f256b6a&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/eb33eae22384eb25f2f1d70910e1e5803eeb8964417005a674425a09357002f4?apiKey=94100daacd774519986c0b390f256b6a&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/eb33eae22384eb25f2f1d70910e1e5803eeb8964417005a674425a09357002f4?apiKey=94100daacd774519986c0b390f256b6a&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/eb33eae22384eb25f2f1d70910e1e5803eeb8964417005a674425a09357002f4?apiKey=94100daacd774519986c0b390f256b6a&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/eb33eae22384eb25f2f1d70910e1e5803eeb8964417005a674425a09357002f4?apiKey=94100daacd774519986c0b390f256b6a&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/eb33eae22384eb25f2f1d70910e1e5803eeb8964417005a674425a09357002f4?apiKey=94100daacd774519986c0b390f256b6a&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/eb33eae22384eb25f2f1d70910e1e5803eeb8964417005a674425a09357002f4?apiKey=94100daacd774519986c0b390f256b6a&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/eb33eae22384eb25f2f1d70910e1e5803eeb8964417005a674425a09357002f4?apiKey=94100daacd774519986c0b390f256b6a&"
                    className="self-center max-w-full aspect-square w-[120px]"
                  />
                  <div className="mt-5 text-lg font-medium leading-7 text-center text-gray-900">
                    Ruben Harutyunyan
                  </div>
                  <div className="mt-2 text-base leading-6 text-center text-gray-500">
                    Product Designer at Digitain,  Data Analyst
                  </div>
                  <div className="flex justify-center items-center px-16 mt-10 max-md:px-5">
                    <div className="flex gap-4" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center px-16 py-20 w-full bg-white max-md:px-5 max-md:max-w-full">
        <div className="flex flex-col justify-center px-8 py-16 my-4 w-full max-w-screen-xl bg-gray-50 max-md:px-5 max-md:max-w-full">
          <div className="justify-end max-md:pl-5 max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
              <div className="flex flex-col w-[76%] max-md:ml-0 max-md:w-full">
                <div className="flex flex-col grow font-semibold max-md:mt-10 max-md:max-w-full">
                  <div className="text-base leading-6 text-purple-700 max-md:max-w-full">
                    FAQs
                  </div>
                  <div className="mt-3 text-5xl tracking-tighter text-gray-800 max-md:max-w-full max-md:text-4xl">
                    Do you have a question?
                  </div>
                  <div className="mt-4 text-xl leading-8 text-gray-500 max-md:max-w-full">
                    Have questions? We’re here to help.
                  </div>
                </div>
              </div>
              <div className="flex flex-col ml-5 w-[24%] max-md:ml-0 max-md:w-full">
                <div className="grow gap-5 items-start self-stretch mt-28 text-lg leading-8 text-teal-400 underline whitespace-nowrap max-md:mt-10">
                  info@gait.network
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center px-16 py-12 w-full text-base leading-6 text-right text-gray-200 bg-teal-400 max-md:px-5 max-md:max-w-full">
        <div className="flex gap-5 justify-between w-full max-w-[1216px] max-md:flex-wrap max-md:max-w-full">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/3b52958cbce7daf232f9dc6ddcd56c8711efa2936b5779ed55aaaaa3e25d8237?apiKey=94100daacd774519986c0b390f256b6a&"
            className="shrink-0 aspect-[1.27] w-[71px]"
          />
          <div className="my-auto">© 2023 Gait Network</div>
        </div>
      </div>
    </div>
  );
}


export default App;